.searchbox-wrap {
    margin-top: -100px;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    border-radius: 15px;
    padding-bottom: 50px;
}

.box-shadow-grey {
    box-shadow: 0 0 10px darkgrey;
}

.searchbox-container {
    background: white;
    /* box-shadow: 0 0 10px; */
    border-radius: 10px;
    /* box-shadow: 0 0 10px darkgrey; */
    z-index: 2;
    position: relative;
}

.searchbox-container .row {
   width: inherit;
   padding: 40px 24px;
}

.searchbox-container .row .col:not(.separator) {
   padding: 3px;
}

.searchbox-container .select-box {
    border: 1px solid black;
    border-radius: 15px;
    padding: 10px 25px;
}

.searchbox-container .separator {
    border: 1px solid;
    border-radius: 50%;
    position: absolute;
    width: 45px;
    height: 45px;
    left: 223px;
    top: 74px;
    background: white;
}

.searchbox-container .separator .circle {
    margin: 7px -2px;
}

.departure-month, .return-month {
    align-items: center;
    gap: 10px;
}

.departure-year, .return-year {
    display: block;
    margin-top: -7px;
}

.departure-month h3, .return-month h3 {
    padding-top: 7px;
    font-size: 1.1rem !important;
}

.select-date-separator {
    /* margin-bottom: 30px; */
    margin-bottom: 54px;
    margin-top: -5px;
    background: black;
    width: 1px;
    margin-right: 16px;
}

.ov-badge-wrap {
    z-index: 3;
    box-shadow: none;
    background: transparent;
    padding: 0 !important;
}

.select-box:hover {
    cursor: pointer;
}

.from-city-name {
    display: flex;
    justify-content: space-between;
}

.from-city-name, .to-city-name {
    max-height: 28px;
}

.from-city-name .circle {
    border-radius: 50%;
    border: 1px solid;
    width: 36px;
    text-align: center;
    margin-right: -47px;
    z-index: 9;
    background: white;
    padding: 5px 0;
    height: 100%;
}

.from-city-name .circle svg {
    margin-top: -5px;
}

.top-stamp {
    background: #ffffff;
    width: 6px;
    height: 10px;
    margin-left: 14px;
    margin-top: -10px;
}

.bottom-stamp {
    background: #ffffff;
    width: 6px;
    height: 10px;
    margin-left: 14px;
    margin-bottom: -10px;
}

.basic-single {
    width: 90%;
    margin: -57px 5%;
}

.basic-single .select__control:hover {
    cursor: text;
}

.basic-single .select__control {
    border: none;
}

.departure-date, .return-date {
    padding: 0;
    font-size: 0;
    line-height: 0;
}

.searchbox-city {
    display: flex;
}

.select-date-container {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
}


