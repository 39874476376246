ul {
    display: flex;
}

.overlay {
    background-color: gray;
}

.header-separator {
    background: var(--default-blue-color);
    height: 10px;
}

.image-header img {
    height: auto;
    filter: brightness(0.5);
}