.navbar-nav a {
    color: white;
    text-decoration: none;
}

.brand-logo {
    margin-right: 75px !important;
}

.brand-logo img {
    max-width: 65%;
}

.nav-container {
    width: auto !important;
}

.nav-link-wrap {
    gap: 25px;
}

.navbar {
    position: fixed !important;
    z-index: 99;
    width: 100%;
    transition: 0.5s;
}

.navbar-nav a .nav-item {
    border-radius: 7px;
    padding: 0 20px;
}

.active-nav {
    background-color: var(--default-blue-color);
}

.nav-scrolled {
    transition: 0.5s;
    background-color: rgb(3 3 3 / 80%);
    box-shadow: 5px -1px 12px -5px grey;
}