.slider-item {
    max-height: 100vh;
}

.slide-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #0000008a;
}

.home-carousel .carousel-caption {
    right: 0;
    top: 25%;
    left: 10%;
}

.home-carousel .carousel-caption .primary-label {
    max-inline-size: 400px;
    font-size: 4rem;
    text-align: left;
    font-family: 'rubik-semibold';
}

.home-carousel .carousel-caption .secondary-label {
    max-inline-size: 400px;
    margin-inline: auto;
    font-size: 4rem;
    text-align: right;
    margin-right: 10%;
    margin-top: -4%;
    font-family: 'rubik-semibold';
}

.lazy-load-image-loaded {
    width: 100%;
}