:root {
    --default-blue-color: #110b77;
    --default-dark-color: #18181e;
    --default-grey-color: #cdcdcd;
    --default-lightgrey-color: #D9D9D9;
    --default-transparentgrey-color: rgba(205, 205, 205, 0.2);
    --default-orange-color: #ff5b02;
}

@font-face {
    font-family: 'rubik-regular';
    src: url(../assets/fonts/rubik/Rubik-Regular.ttf);
}

@font-face {
    font-family: 'rubik-bold';
    src: url(../assets/fonts/rubik/Rubik-Bold.ttf);
}

@font-face {
    font-family: 'rubik-medium';
    src: url(../assets/fonts/rubik/Rubik-Medium.ttf);
}

@font-face {
    font-family: 'rubik-small';
    src: url(../assets/fonts/rubik/Rubik-Light.ttf);
}

@font-face {
    font-family: 'rubik-semibold';
    src: url(../assets/fonts/rubik/Rubik-SemiBold.ttf);
}

* {
    font-family: 'rubik-regular';
}

.bg-transgrey-color {
    background: var(--default-transparentgrey-color);
}

.font-bold {
    font-family: 'rubik-bold';
}

.font-semibold {
    font-family: 'rubik-semibold';
}

.font-medium {
    font-family: 'rubik-medium';
}

.font-small {
    font-family: 'rubik-small';
}

body {
    overflow-x: hidden;
}

[data-root="true"] {
    overflow: hidden;
}

.carlist-wrap {
    margin-top: -35px;
    z-index: 9;
    position: relative;
}

.car-wrap {
    /* width: 50%;
    margin: 0 25%; */
    background-color: var(--default-blue-color);
    width: 90%;
    margin: 0 5%;
    border-radius: 17px;
    padding: 0 12px;
    transition: 0.5s;
}

/* .car-wrap:active, .car-wrap:hover {
    transition: 0.5s;
    border: 5px solid #ff5b02;
} */

.car-wrap.active {
    transition: 0.5s;
    border: 5px solid #ff5b02;
}

.car-wrap:hover {
    cursor: pointer;
}

.car-wrap .row {
    width: 100%;
}

.check-price-detail .car-detail {
    color: black;
}

.car-detail {
    color: white;
    text-align: left;
    width: 63%;
    padding-left: 5%;
    line-height: 18px;
    align-self: center;
    display: grid;
}

.car-detail h2 {
    width: 300%;
}

.car-background {
    border-radius: 12px;
    background-color: white;
    width: 37%;
}

.car-badge {
    background-color: var(--default-blue-color) !important;
    font-size: larger;
    font-weight: 600;
    border-radius: 25px;
    padding: 6px 32px;
}

.bg-dark-blue {
    background-color: var(--default-blue-color) !important;
}

.bg-default-grey {
    background-color: var(--default-grey-color) !important;
}

.bg-default-lightgrey {
    background-color: var(--default-lightgrey-color) !important;
}

.bg-default-orange {
    background-color: var(--default-orange-color) !important;
}

.check-price-btn {
    border-radius: 22px;
    padding: 5px 50px;
    margin-top: 20px;
}

.check-price-separator {
    background: var(--default-orange-color);
    height: 3px;
    width: 86%;
    margin: 20px 7%;
}

.check-price-detail {
    width: 86%;
    margin: 0 7%;
}

.check-price-wrap h2, .check-price-wrap small {
    color: black;
}

.col.check-price-rent, .col.check-price-back {
    text-align: left;
    line-height: 5px;
}

.w-triple {
    width: 33.3% !important;
}

.action-button button {
    border-radius: 15px;
    width: 107%;
}

.price-wrap {
    background: #D9D9D9;
    border-radius: 15px;
    align-items: baseline;
    padding-top: 1px;
}

.city-badge {
    padding: 3px 10px;
    font-size: small;
    border-radius: 10px;
}

.timeline-line {
    position: relative;
    height: 50px;
    width: 84%;
    border-bottom: 2px solid black;
    z-index: 99;
    margin: 3.5px 8%;
}

.timeline-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.timeline-marker {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: relative;
    background: black;
    margin-top: -10px;
    left: 11px;
}

.gallery-wrap {
    margin-top: 3rem;
    display: grid;
}
    
.gallery-wrap .swiper-slide-active {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    transform: scale(1.3);
}

.gallery-wrap .swiper-wrapper .swiper-slide {
    padding-top: 50px;
    /* max-width: 492px; */
}

.car-slide {
    display: inline;
}

.col.bg-car {
    width: 50%;
    margin: 0 25%;
    direction: rtl;
    border-radius: 15px;
}

.car-slide .new-badge {
    float: left;
    position: relative;
    z-index: 9;
    transform: scale(0.6);
    margin-left: -30px;
    margin-top: -31px;
}

.car-slide .car-image {
    max-width: 250px
}

.hidden {
    visibility: hidden;
}

.gallery-badge-car {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.gallery-badge-car p {
    font-size: small;
    color: white;
}

.typography {
    text-align: center;
    margin: 70px auto;
    font-size: larger;
    font-weight: 700;
}

/* .text-slide-wrap {
    max-width: 70%;
    background: var(--default-blue-color);
    border-radius: 10px;
    padding: 6%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
} */

.text-slide-wrap {
    max-width: 50%;
    /* background: var(--default-blue-color); */
    border-radius: 10px;
    padding: 4% 3%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
}

.text-slider-wrap {
    width: 80%;
}

.text-slider .swiper-button-prev {
    margin-left: 16%;
    color: white;
}

.text-slider .swiper-button-next {
    margin-right: 16%;
    color: white;
}

/* .text-slider .swiper-slide {
    background: var(--default-orange-color);
} */
/* 
.text-slider-wrap .swiper-button-prev:after, .text-slider-wrap .swiper-button-next:after {
    font-size: x-large;
    z-index: 99;
    position: absolute;
    color: white;
} */

/* .text-slider-wrap .swiper-button-next {
    margin-right: 180px;
    width: inherit;
    background: var(--default-orange-color);
    height: 115px;
    justify-content: right;
    z-index: 0;
    margin-top: -55px;
    padding-right: 12px;
    border-radius: 15px;
}

.text-slider-wrap .swiper-button-prev {
    margin-left: 180px;
    width: inherit;
    background: var(--default-orange-color);
    height: 115px;
    justify-content: left;
    z-index: 0;
    margin-top: -55px;
    padding-left: 12px;
    border-radius: 15px;
} */

.text-slider {
    font-size: larger;
    font-weight: 700;
    color: white;
}

.bg-city {
    background-size: 56% !important;
    background-repeat: no-repeat !important;
    background-position: right !important;
    margin-top: -175px;
    width: 101%;
}

.gallery-pic-wrap img {
    max-width: inherit;
}

.photo-gallery-wrap {
    background: var(--default-blue-color);
    color: white;
    border-radius: 30px;
    padding: 2%;
}

.text-right {
    text-align: right;
}

.image-card {
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 0 10px darkgrey;
    padding: 0;
}

.image-card.orange {
    background: var(--default-orange-color);
}

.image-card.blue {
    background: var(--default-blue-color);
}

.image-card img {
    width: 100%;
}

.image-card .text-wrap {
    font-size: 3vw;
    padding: 4%;
    font-weight: 700;
    color: white;
}

.image-card .button-wrap {
    padding: 0 40px;
}

.image-card.orange .button-wrap button {
    color: var(--default-orange-color) !important;
}

.text-orange {
    color: var(--default-orange-color) !important;
}

.image-card.blue .button-wrap button {
    color: var(--default-blue-color) !important;
}

.right-border-radius {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.left-border-radius {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.maps {
    width: 100%;
    height: 50vh;
}

.footer {
    background-color: var(--default-blue-color);
    width: inherit;
}

.footer-container {
    padding: 68px;
}

.footer .company-name {
    max-height: 10px;
    font-weight: 800;
}

.footer .company-address {
    max-inline-size: 361px;
}

.footer .contact-item {
    display: flex;
    gap: 10px;
    align-items: center;
}

.footer .city-container {
    align-self: center;
    text-align: end;
}

.footer .city-container img {
    max-width: 100%;
}

.page-loader {
    display: flex;
    width: 100%;
    position: absolute;
    top: 0;
    background: white;
    z-index: 999999;
    overflow: hidden;
    height: 100%;
}

.page-loader svg {
    transform: scale(0.1);
}

.aboutus-badge {
    font-size: xx-large;
    font-family: 'rubik-semibold';
    box-shadow: 0 0 10px darkgrey;
    cursor: default !important;
}

.vision-wrap, .mission-wrap {
    padding: 8%;
    background-repeat: no-repeat !important;
    background-position-y: bottom !important;
}

.vision-wrap {
    background-position-x: left !important;
    background-size: 42% !important;
}

.mission-wrap {
    background-position-x: right !important;
    background-size: 52% !important;
}

.headquarter-wrap {
    padding: 8%;
}

.headquarter-header {
    text-align: center;
    font-family: 'rubik-semibold';
    font-size: xx-large;
}

.headquarter-item .heading {
    max-height: 10px;
    font-family: 'rubik-semibold';
}

.text-dark-blue {
    color: var(--default-blue-color);
}

.company-value-wrap {
    background: var(--default-blue-color);
    color: white;
}

.headquarter-icon svg {
    font-size: xx-large;
    margin-top: 20px;
}

.headquarter-office {
    margin-bottom: 6%;
}

.headquarter-office .address {
    max-inline-size: 370px;
}

.advantages-icons {
    padding: 3% 15% 10% 15%;
}

.advantages-header>* {
    font-size: xx-large;
    font-family: 'rubik-semibold';
}

.service-wrap {
    padding: 0 10% 10% 10%;
    margin-top: 1.5rem;
}

.service-img {
    /* height: 270px; */
    height: auto;
    border-radius: 15px;
}

.service-number {
    width: max-content;
    padding: 0 1%;
    border-radius: 7px;
    position: absolute;
    height: 30px;
    margin-left: -5px;
    margin-top: -5px;
    font-size: larger;
    color: white;
    background: var(--default-blue-color);
}

.company-value-header {
    text-align: center;
    font-family: 'rubik-semibold';
}

.company-value-img {
    border-radius: 15px;
}

.company-value-content, .company-value-vision {
    padding: 0 12% 2%;
}

.company-value-vision {
    padding: 0 6% 2%;
}

.image-card-wrap {
    /* padding: 0 14%; */
    padding: 0 20%;;
}

.company-value-desc {
    font-size: 1.3vw;
}

.company-value-vision img {
    width: 27%;
    height: 100%;
}

.company-value-vision .header-text {
    height: 15px;
}

.left-cloud {
    position: absolute;
    width: 11%;
    left: 0;
}

.right-cloud {
    position: absolute;
    width: 11%;
    right: 0;
    display: grid;
    margin-top: 250px;
}

.promo-wrap {
    background: var(--default-blue-color);
    color: white;
}

.promo-badge {
    background-color: var(--default-orange-color) !important;
    font-size: larger;
    font-weight: 600;
    border-radius: 10px;
    padding: 6px 50px;
    font-family: 'rubik-semibold';
}

.promo-item .img-wrap {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.promo-item {
    background: white;
    width: 80%;
    margin: 0 10%;
    color: black;
    border-radius: 45px;
    max-height: 85vh;
    box-shadow: 0 0 10px darkgrey;
}

.promo-item img {
    height: 85%;
    width: auto;
}

.promo-desc {
    padding: 6% 4%;
}

.promo-desc button {
    float: right;
    display: block;
    margin-top: 12%;
}

.promo-name {
    font-size: 5vw;
    font-family: 'rubik-bold';
    color: var(--default-blue-color);
}

.promo-wrap .badge-wrap {
    margin-bottom: 1.5rem;
    margin-top: 3rem;
}

.footer .footer-logo {
    margin-top: 3rem;
}

@media screen and (max-width:500px) {
    .footer-container {
        padding: 0 35px 5px 35px;
    }

    .company-value-vision {
        display: grid;
    }

    .company-value-vision .container {
        width: auto !important;
        margin-bottom: 20px;
    }

    .company-value-content {
        padding: 0;
        display: flex;
        margin-left: 12px;
    }

    .company-value-content .swiper-button-next,
    .company-value-content .swiper-button-prev {
        color: orange;
        width: 12%;
        transform: scale(0.6);
        margin-top: -50px;
    }

    .company-value-content .service-item {
        width: 70%;
    }
    
    .company-value-header {
        margin-left: 16px;
    }

    .company-value-desc {
        font-size: 3.5vw;
    }

    .company-name, .company-address {
        margin-right: 5px;
    }

    .left-cloud, .right-cloud {
        width: 25%;
    }

    .footer {
        /* background: linear-gradient(180deg, #110b77, #030218); */
        background: var(--default-blue-color);
        margin-top: -1px;
    }

    .footer .footer-logo {
        text-align: center;
        margin-top: 1.5rem !important;
    }

    .footer .footer-logo img {
        margin-left: -3px;
        width: 60%;
    }

    .service-item {
        width: auto;
        text-align: center;
    }

    .service-img {
        width: auto;
    }

    .service-wrap {
        margin-top: 0;
    }

    .service-wrap .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
        margin-bottom: -34px;
    }

    .advantages-icons {
        padding: 0 2% 10% 6%;
        margin-left: -6px;
    }

    .headquarter-wrap {
        margin-bottom: -20px;
    }

    .headquarter-office {
        margin-bottom: 2%;
        width: auto;
        background: rgba(217, 217, 217, 0.24);
        border-radius: 20px;
        padding: 10px 5px;
    }

    .headquarter-header-wrap {
        margin-bottom: 1.5rem !important;
    }

    .headquarter-icon svg {
        font-size: x-large;
        margin-top: 24px;
    }

    .vision-wrap, .mission-wrap {
        width: auto;
    }

    .about-desc-wrap {
        text-align: justify;
        margin-bottom: -45px !important;
    }

    .about-desc-wrap .container {
       width: auto;
    }

    /* .about-desc-wrap .container:nth-child(2) {
        padding-right: 0 !important;
        position: absolute;
        z-index: -1;
        top: -7%;
    } */

    .right-cloud {
        margin-top: 1100px;
    }

    .company-value-vision .font-small {
        font-size: 3vw;
        text-align: justify;
    }

    .footer .company-address {
        /* margin-bottom: 40px;
        font-size: smaller;
        max-inline-size: 300px; */
        margin-bottom: 30px;
        font-size: small;
        max-inline-size: 300px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .footer .footer-logo .contact {
        font-size: small;
        display: flex;
        width: 110%;
        margin: 0 -2%;
    }

    .footer .contact-item {
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 2.5vw;
        width: 38%;
    }

    .footer .contact-item .contact-link svg {
        margin-top: -2px;
    }

    .footer .contact-item:nth-child(2) {
        width: 52%;
    }

    .navbar-toggler {
        margin-left: auto;
    }

    .footer .city-container {
        width: 80%;
        margin: 0 19%;
        display: none;
    }

    .nav-scrolled {
        background: #00000094;
    }

    .bg-transparent-dark {
        background: #000000b0;
    }

    .navbar-toggler-icon {
        filter: invert(1);
    }

    .searchbox-wrap {
        margin-top: -50px;
        width: auto;
        padding-bottom: 0;
    }

    .searchbox-city {
        display: grid;
        width: auto !important;
        z-index: 9999;
    }

    .MuiModal-root {
        z-index: 99999 !important;
    }
/* 
    .searchbox-container .row {
        width: max-content;
        padding: 4% 2%;
    } */

    .searchbox-container .row {
        width: auto;
        padding: initial;
        margin-top: 20px;
        margin-right: 1px;
        margin-left: 1px;
    }

    .searchbox-container .select-date .select-box {
        width: auto;
        /* padding: 5px 25px; */
        padding: initial;
    }

    .searchbox-container .select-city .select-box {
        /* width: 170px; */
        width: auto;
        padding: 5px 25px;
    }

    .from-city-name .circle {
        margin-top: -10px;
    }

    .select-box h3 {
        font-size: 3.8vw !important;
        font-family: 'rubik-semibold';
    }
    
    .searchbox-container .select-city .select-box small:first-child {
        font-family: 'rubik-semibold';
    }

    .departure-container b, .return-container b {
        font-family: 'rubik-semibold';
    }

    .from-city-name, .to-city-name {
        max-height: 20px;
    }

    .searchbox-container .select-date {
        width: auto;
        margin-right: 3px;
        margin-left: 3px;
        margin-bottom: -10px;
    }

    .departure-container {
        margin-left: 0px !important;
    }

    .select-date-separator {
        margin-bottom: 20px;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 5px;
        width: 1.5px;
        transform: translateX(-8px);
    }

    .select-date-container {
        padding-right: 0;
        padding-left: 0;
        padding-top: 0;
        justify-content: center;
    }

    .select-date-container .departure-container,
    .select-date-container .return-container {
        /* padding: 0px 10px; */
        /* padding: 0px 0px 3px 10px; */
        width: auto;
        max-height: 85px;
        margin: 0 -19px;
    }

    .select-date-container .return-container {
        transform: translateX(20px);
    }

    .select-date-container .departure-container {
        transform: translateX(10px);
    }

    .departure-container, .return-container {
        width: auto;
        margin-top: -3px;
    }

    .departure-month, .return-month {
        height: 27px;
        gap: 17px;
        margin-right: 6px;
    }

    .home-carousel .carousel-caption .primary-label,
    .home-carousel .carousel-caption .secondary-label {
        font-size: revert;
    }

    .ov-badge-wrap {
        margin-top: 10px;
        z-index: 99999;
    }

    .gallery-wrap {
        margin-top: 50px;
        display: flex;
    }

    .col.bg-car {
        width: 82%;
        margin: auto;
        padding-top: 8px;
    }

    .gallery-wrap .swiper-wrapper .swiper-slide {
        padding-top: 0px;
    }

    .gallery-wrap .swiper-slide-active {
        -webkit-transform: scale(1.15);
        -moz-transform: scale(1.15);
        transform: scale(1.15);
    }

    .car-slide .car-image {
        max-width: 100%;
    }

    .gallery-badge-car {
        border-radius: 15px;
    }

    .typography {
        max-inline-size: 95%;
        text-align: justify;
        margin: 0 auto 30px;
    }

    .text-slider-wrap {
        width: 100%;
    }

    .text-slider-wrap .swiper-button-prev:after, .text-slider-wrap .swiper-button-next:after {
        color: var(--default-orange-color);
    }

    .text-slide-wrap {
        max-width: 98%;
        padding: 6% 6%;
        font-size: small;
        height: 143px;
    }

    .bg-city {
        background-position-x: right !important;
        margin-top: -310px;
        background-position-y: bottom !important;
    }

    .image-card {
        width: 90%;
        margin: auto;
    }

    .image-card .text-wrap {
        /* font-size: larger; */
        font-size: 5vw;
        font-family: 'rubik-semibold';
    }

    .image-card .button-wrap {
        padding: 0;
    }

    .image-card .button-wrap button {
        padding: 2px 10px;
        font-size: 3vw;
    }

    .image-card-wrap {
        padding: 0;
        margin-bottom: 60px;
    }

    .promo-wrap .badge-wrap {
        margin-bottom: 1rem;
        margin-top: 2rem;
    }

    .promo-item {
        height: 160px;
        width: 90%;
        border-radius: 17px;
        margin: 0 5%;
    }
    
    .promo-item .img-wrap {
        height: inherit;
    }

    .promo-name {
        font-size: 8vw;
        margin-bottom: 0;
    }

    .promo-desc {
        padding: 6% 5% 6% 2%;
    }

    .promo-desc .font-semibold {
        font-size: 9px;
        text-align: justify;
    }

    .promo-desc button {
        margin-top: 0;
        font-size: 9px;
        padding: 2px 6px;
        border-radius: 9px;
    }

    .btn-other-promo {
        width: auto;
        padding: 1px 10px;
        border-radius: 10px;
    }

    .navbar-collapse {
        margin-top: 20px;
    }

    .carlist-wrap {
        margin-top: -10px;
    }

    .car-background {
        padding: 0;
    }

    .car-background img {
        width: 115%;
        /* height: max-content; safari bug stretch */
        height: auto;
        align-self: center;
    }

    .car-detail h2 {
        font-size: medium;
        font-family: 'rubik-semibold';
        padding-top: 4px;
    }

    .car-detail small {
        font-size: x-small;
        line-height: 11px;
        font-family: 'rubik-small';
        padding-bottom: 7px;
        width: 200px;
    }

    .check-price-btn {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 4vw;
    }

    .check-price-detail .separator {
        display: none;
    }

    .check-price-rent {
        margin-bottom: 20px;
    }

    .price-wrap {
        height: auto;
    }

    .price-wrap .price-title h3 {
        /* font-size: medium; */
        font-size: 4vw !important;
    }

    .overlay-badge p {
        margin: 0px auto 0 10px;
        font-size: 4vw;
    }

    .price-wrap .price-total h3 {
        /* font-size: small; */
        font-size: 3.5vw !important;
    }

    /* .price-wrap .action-button button {
        font-size: small;
        padding: 0px 10px;
    } */
    .price-wrap .action-button button {
        font-size: 3vw;
        padding: 5px 10px;
    }

    .tagline-text {
        text-align: center !important;
        font-size: 3vw;
    }

    .btn-search-car {
        margin-top: -15px !important;
        border-radius: 15px !important;
        width: 200px !important;
        height: 47px !important;
        font-size: 4.5vw;
        margin: 0 auto;
    }

    .btn-search-car svg {
        margin-left: 10px;
        margin-bottom: 2px;
    }

    .text-slider.title {
        font-size: medium !important;
    }

    .select-ov {
        margin-top: 30px;
        margin-left: 10px;
    }

    .about-desc-wrap .container:last-child img {
        width: 70% !important;
        margin: -40px 15% -60px;
    }

    p.about-desc {
        font-size: 12px !important;
        border-radius: 10px;
        background: rgba(217, 217, 217, 0.24);
        padding: 10px;
    }

    .text-slider.content {
        font-size: smaller !important;
        max-inline-size: 250px !important;
    }

    .select__menu {
        margin-left: -5px;
    }

    .select__menu>* {
        /* position: absolute !important;
        z-index: 999 !important; */
        /* max-height: 140px !important; */
    }

    /* .select-ov {
        margin-top: 7px;
    } */

    .select-box h3 {
        font-size: 1.5rem;
    }

    .slider-item {
        max-height: 80vh;
    }

    .text-slider .swiper-button-prev {
        margin-left: -2%;
        color: white;
        transform: scale(0.6);
    }

    .text-slider .swiper-button-next {
        margin-right: -2%;
        color: white;
        transform: scale(0.6);
    }

    .left-bg {
        background: var(--default-orange-color);
        width: 100px;
        height: 100px;
        position: absolute;
        margin-top: 25px;
    }

    .right-bg {
        background: var(--default-orange-color);
        width: 100px;
        height: 100px;
        position: absolute;
        margin-top: -119px;
        right: 0;
    }

    .ov-badge-wrap.search-car {
        margin-top: 21px;
        position: relative;
        z-index: 0;
    }

    .basic-single {
        margin: -75px 5%;
    }

    .slider-bg {
        width: 100%;
        background: var(--default-orange-color);
        height: 12vh;
        position: absolute;
        margin: 30px 0;
        border-radius: 0;
    }

    .advantages-icons-mobile {
        max-width: 100%;
        margin: -15px auto 20px;
        justify-content: center;
    }

    .advantages-icons-mobile .col {
        padding: 13px;
    }

    .aboutus-badge {
        font-size: inherit;
        padding: 1px 8px;
        border-radius: 5px;
        margin-left: 8px;
    }

    .about-desc-container {
        padding: 30px !important;
    }

    p.about-desc {
        margin-top: -7px !important;
    }

    .vision-desc, .mission-desc {
        font-size: smaller;
        text-align: justify;
        max-inline-size: 95%;
        font-family: 'rubik-small';
    }

    .mission-wrap {
        margin-top: -30px
    }

    .bg-city-mobile {
        background-position-x: center !important;
        margin-top: -235px;
        background-position-y: bottom !important;
        background-size: 85% !important;
        opacity: 0.7;
    }

    .headquarter-header {
        font-size: 6vw;
    }

    .headquarter-office .address {
        font-weight: bold;
        font-size: 3.5vw;
    }

    .headquarter-item a {
        padding: 1px 7px;
    }

    .headquarter-item a svg {
        margin-left: 6px;
    }

    .advantages-header h3 {
        font-size: 6vw;
    }

    .service-wrap .swiper-button-prev,
    .service-wrap .swiper-button-next {
        width: 0;
    }

    .service-wrap .swiper-button-prev::after,
    .service-wrap .swiper-button-next::after {
        transform: scale(0.5);
    }

    .service-number {
        width: 30px;
        height: 30px;
        margin-left: 6%;
        margin-top: -5px;
    }

    .select-city-h {
        margin-left: -6px;
        width: 121.5% !important;
    }

    .gallery-pic-wrap {
        display: flex;
        justify-content: center;
    }

    .ov-badge-wrap .overlay-badge {
        margin-bottom: -30px;
    }
    
    .photo-gallery-wrap.mobile .gallery-pic-wrap > img {
        /* width: auto; */
        width: 105%;
        border-radius: 15px !important;
    }

    .searchbox-container.search-car:not(.open) {
        background: white;
        height: 11px;
        box-shadow: 0 10px 10px lightgrey;
    }

    .photo-gallery-wrap .tagline {
        margin-bottom: -7px;
    }    

    .photo-gallery-wrap.mobile .swiper {
        margin-bottom: -33px;
    }    
    
    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: var(--swiper-pagination-bottom, 32px) !important;
        background: white;
        width: max-content;
        margin: 0 35%;
        border-radius: 20px;
        height: 15px;
        padding: 4px 3px;
        display: flex;
    }

    :root {
        --swiper-pagination-bullet-inactive-color: var(--default-blue-color);
    }

    .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 var(--swiper-pagination-bullet-horizontal-gap,2px);
    }

    .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet:not(.swiper-pagination-bullet-active), .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
        background: var(--default-blue-color) !important;
    }

    .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: var(--default-orange-color);
    }

    .typography p {
        font-size: 0.69rem !important;
    }

    .navbar-nav a .nav-item {
        font-family: 'rubik-semibold';
        font-size: 7vw;
        transition: 0.5s;
    }

    .navbar>.container {
        align-items: baseline;
        align-content: normal;
        height: 100vh;
        transition: 0.5s;
    }

    .nav-item-underlined {
        transition: 0.5s;
        text-decoration: underline !important;
        text-underline-position: under;
        text-decoration-thickness: 1.5px !important;
        -webkit-text-decorations-in-effect: line-through;
    }

    .hover-underline {
        display: inline-block;
        position: relative;
    }
      
    .hover-underline::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #0087ca;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }
      
    .hover-underline:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
      

    .navbar.nav-height-auto>.container {
        transition: 0.5s;
        margin-top: -8px;
    }

    .nav-brand-wrap {
        margin-top: 70px;
    }

    /* .nav-mobile.bg-transparent-dark:not(.bg-transparent) #basic-navbar-nav {
        height: inherit;
        display: contents;
    } */

    .nav-height-auto .container {
        height: auto;
    }

    .nav-height-auto.bg-transparent-dark .container {
        height: 100vh;
    }

    .nav-height-auto.bg-transparent .container {
        height: 100vh;
    }

    .nav-height-auto.nav-scrolled.bg-transparent-dark .container {
        background-color: var(--default-blue-color) !important;
    }

    /* .navbar:not(.bg-transparent):not(.nav-scrolled)>.container {
        height: 100vh;
    } */

    /* .navbar.bg-transparent>.container {
        height: 100vh;
    } */
    
    .navbar.navbar-expand-lg:not(.bg-transparent):not(.nav-scrolled) {
        background-color: var(--default-blue-color) !important;
    }

    .nav-brand-logo {
        width: 100%;
        margin-left: 45vw;
    }

    .nav-brand-logo img {
        width: 47%;
    }

    .nav-city-logo {
        text-align: end;
    }

    .nav-city-logo img {
        width: 85%;
    }

    .navbar-nav a:active {
        color: var(--default-orange-color);
    }

    .navbar {
        z-index: 999999;
    }

    .home-carousel .carousel-caption .primary-label,
    .home-carousel .carousel-caption .secondary-label {
        max-inline-size: 200px;
    }

    .car-detail small>* {
        /* width: auto !important; */
        width: 104% !important;
        margin-left: -26px;
        font-size: smaller;
    }

    .action-button.w-triple {
        padding: 0;
    }

    .check-price-detail .car-detail {
        margin-left: -26px !important;
    }

    .select-box .to-city-name {
        width: 120% !important;
    }
    
    /* .navbar.nav-scrolled.bg-transparent-dark .container {
        height: 100vh;
        align-items: baseline;
        align-content: normal;
        background-color: var(--default-blue-color) !important;
    } */
}

.headquarter-header-wrap {
    margin-bottom: 3rem;
}

p.about-desc {
    margin-top: 1rem;
}

.about-desc-container {
    padding: 3rem;
}

@media screen and (max-width: 367px) {
    .searchbox-container .row {
        /* width: max-content !important; */
        width: auto;
    }

    .navbar-brand {
        width: 75%;
    }
}

@media screen and (min-width: 900px) {
    .headquarter-office .row {
        background: #d9d9d933;
        width: 90%;
        padding: 10px 0px;
        border-radius: 25px;
    }

    .navbar {
        z-index: 9999;
    }

    body .MuiPickersPopper-root:first-child .MuiPaper-root {
        margin-left: -90px;
    }

    .MuiPaper-root {
        margin-top: -47px !important;
        margin-left: -47px !important;
        transform: scale(0.8) !important;
    }

    .select-date .select-box.col {
        /* height: 73.4%; datepicker normal */
        height: 64% !important;
        margin-top: 2.5px;
    }
    
    .searchbox-container.searchbox-city {
        box-shadow: 0 0 10px darkgrey;
    }

    .searchbox-wrap .overlay-badge {
        margin-top: 30px;
        margin-bottom: -20px;
    }

    .departure-month h3, .return-month h3 {
        width: auto !important;
    }

    .select-box h3 {
        font-size: medium;
        width: 150px; 
        font-family: 'rubik-semibold';   
    }    

    .slider-bg {
        /* width: 72%;
        background: var(--default-orange-color);
        height: 12vh;
        position: absolute;
        margin: 5vh 14%;
        border-radius: 15px; */
        background: var(--default-orange-color);
        border-radius: 15px;
        height: 110px;
        margin: 4% 14%;
        position: absolute;
        width: 72%;
    }

    .gallery-pic-wrap img {
        border-radius: 15px;
    }

    .gallery-wrap .swiper-slide {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        transform: scale(1.2);
    }

    .gallery-wrap .car-slide.container {
        max-width: 1000px;
    }

    .gallery-wrap .swiper-button-prev {
        margin-left: -10px;
    }

    .gallery-wrap .swiper-button-next {
        margin-right: -10px;
    }

    @keyframes bigger {
        0% {
            transform: scale(1.3);
        }
        50% {
            transform: scale(1.4);
        }
        100% {
            transform: scale(1.5);
        }
    }
    
    .gallery-wrap .swiper-slide-active {
        -webkit-transform: scale(1.5);
        -moz-transform: scale(1.5);
        transform: scale(1.5);
        transition: 0.5ms;
    }
    
    .gallery-wrap .swiper.swiper-initialized {
        height: 425px;
        width: 110%;
        margin: 0 -5%;
    }

    /* .gallery-wrap .swiper-slide-active .col.bg-car {
        height: 212px !important;
    } */

    .gallery-wrap .swiper-slide .col.bg-car {
        height: auto;
    }

    .car-slide .car-image {
        max-height: 135px;
    }

    .car-slide .car-image {
        max-width: 216px;
    }

    .searchbox-container.searchbox-city.open {
        box-shadow: none !important;
    }

    .text-slide-wrap {
        /* max-width: 100%; */
        max-width: 60%;
        height: auto;
    }
/* 
    .text-slider .swiper-slide {
        width: 50%;
        margin: 0 25%;
    } */

    .text-slide-wrap .title {
        font-size: 2vw !important;
    }

    .text-slider.content {
        font-size: 1.5vw !important;
    }

    .text-slider .swiper-button-next {
        margin-right: 13%;
    }
    
    .text-slider .swiper-button-prev {
        margin-left: 13%;
    }

    .searchbox-wrap .MuiSnackbar-root {
        margin-top: 30px;
        margin-right: -30px;
    }

    .MuiSnackbar-root.error .MuiPaper-root {
        background: #fe1631;
    }

    .MuiSnackbar-root.success .MuiPaper-root {
        background: green;
    }

    .car-background img {
        min-width: 330px;
    }
}

.gallery-wrap .swiper-button-next:after, .gallery-wrap .swiper-button-prev:after {
    transform: scale(0.35);
    color: var(--default-orange-color);
}

.text-slider .swiper-button-next:after, .text-slider .swiper-button-prev:after {
    transform: scale(0.4);
    color: var(--default-orange-color);
}

.text-slider .swiper-button-next:after {
    margin-left: 3px;
}

.text-slider .swiper-button-prev:after {
    margin-right: 3px;
}

.gallery-wrap .swiper-button-next,
.gallery-wrap .swiper-button-prev {
    border-radius: 50%;
    border: 2px solid var(--default-orange-color);
    height: 35px;
    width: 35px;
    margin-top: 0px;
}

.text-slider .swiper-button-next,
.text-slider .swiper-button-prev {
    border-radius: 50%;
    border: 2px solid var(--default-orange-color);
    height: 35px;
    width: 35px;
}

.car-detail small > * {
    display: block;
    columns: 2;
    --webkit-columns: 2;
    --moz-columns: 2;
    width: 175%;
}

.typography {
    display: flex;
    justify-content: center;
}

.typography p {
    max-inline-size: 690px;
    text-align: center;
    font-size: 1.3rem;
    font-family: 'rubik-semibold';
}

.gallery-pic-wrap img {
    width: 95%;
}

.text-slider.title {
    font-size: x-large;
}

.text-slider.content {
    font-size: 1vw;
    max-inline-size: 60%;
}

.text-slide-wrap .title {
    font-size: 1.5vw;
}

/* .left-bg {
    background: var(--default-orange-color);
    width: 201px;
    height: 16%;
    position: absolute;
    margin-top: 27px;
    margin-left: 15%;
    z-index: -1;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
} */

/* temporary disable nav */
.text-slider-wrap .swiper-button-next::after,
.text-slider-wrap .swiper-button-prev::after {
    display: none !important;
}

.btn-other-promo {
    width: auto;
}

.about-desc-wrap .container:last-child {
    text-align: right;
}

.about-desc-wrap .container:last-child img {
    width: auto;
}

p.about-desc {
    font-size: 1.4rem;
}

.searchbox-wrap .header.col {
    text-align: center;
}

@keyframes fade {
    0% {
        opacity: 0;
   }
    50% {
        opacity: 0.5;
   }
    100% {
        opacity: 1;
   }
}
@keyframes slide {
    0% {
        margin-top: -10px;
   }
    /* 50% {
        margin-top: -5px;
   } */
    100% {
        margin-top: 0px;
   }
}
@keyframes fade_reverse {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
   }
}
@keyframes slide_reverse {
    0% {
        margin-top: 0px;
   }
    100% {
        margin-top: -10px;
   }
}

.tagline-text {
    text-align: right;
}

.fade {
    animation-name: fade, slide;
    animation-duration: 400ms, 600ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear, cubic-bezier(0.23, 1, 0.32, 1);
    animation-delay: 600ms, 0;
}

.fade_reverse {
    animation-name: fade_reverse, slide_reverse;
    animation-duration: 100ms, 200ms;
    animation-timing-function: linear, cubic-bezier(0.23, 1, 0.32, 1);
    animation-delay: 100ms, 0;
}

.fade-out {
    animation-name: fade_reverse;
    animation-duration: 1000ms;
    animation-timing-function: linear;
}

.check-price-detail {
    padding-bottom: 5px;
}

a.contact-link {
    text-decoration: none;
    color: white;
}

a.contact-link:hover {
    color: var(--default-orange-color);
}

.select-city-h {
    margin-top: 2px;
    background: var(--default-blue-color);
    color: white;
    width: 120%;
    position: relative;
    z-index: 9;
    border-top-right-radius: 10px;
    padding: 13px;
}

.select__menu {
    margin-top: -7px !important;
    width: 120% !important;
}

.text-slider-transition {
    opacity: 0;
}

.text-slider-transition-enter {
    transform: translateY(100px);
}

.text-slider-transition-enter-active {
    animation-name: fade, slide;
    animation-duration: 400ms, 600ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear, cubic-bezier(0.23, 1, 0.32, 1);
    animation-delay: 600ms, 0;
}

.desktop .animated {
    opacity: 0;
}

/* .animated {
    opacity: 0;
} */

.text-slider-transition-fade {
    animation-name: fade, slide;
    animation-duration: 1000ms, 1200ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear, cubic-bezier(0.23, 1, 0.32, 1);
    animation-delay: 1000ms, 0;
}

.text-slider-transition-exit {
    transform: translateY(0px);
}

.text-slider-transition-exit-active {
    transform: translateY(800px);
    transition: all 3000ms;
}