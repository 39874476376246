.overlay-badge {
    background: #110B77;
    position: relative;
    z-index: 3;
    display: inline-flex !important;
    align-items: center;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 0;
    padding: 6px;
}

.overlay-badge svg {
    width: auto;
    font-size: 24px;
    color: white;
}

.overlay-badge p {
    margin: 5px 20px 5px 10px;
    font-size: large;
    text-align: left;
    font-weight: 400;
    color: white;
}